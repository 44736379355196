
import { defineComponent, reactive, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { loadMusicInfoData, sendDigitalTransfer } from '@/api/transfer'
import { ITransfer } from './types'
import useUnitText from '@/hooks/unit/useUnitText'
import useError from '@/hooks/transfer/useError'
import Back from '@/components/back/index.vue'
import ShareOverlay from './components/share-overlay.vue'
import Model from './components/model.vue'
import { wxShareInit } from '@/plugins/wechat/share'
import { UNIT_PERCENTAGE } from '@/config/const'
import { multiply, divide } from '@/lib/math'

export default defineComponent({
  name: 'Transfer',
  setup() {
    const route = useRoute()
    const store = useStore()
    const data = reactive<ITransfer>({
      dataSource: {
        title: '',
        musician_name: '',
        cover: '',
        hold: 0,
        sum: 1000000,
      },
      num: '',
      cursor: false,
      keyboardVisible: false,
      shareVisible: false,
      modelVisible: false,
    })
    // 单位
    const unitText = useUnitText()
    // 单位类型
    const isPercentage = computed(() => store.state.unit === UNIT_PERCENTAGE)
    // 持有量
    const holder = computed(() => {
      let {
        dataSource: { amount, origin_assets },
      } = data
      return isPercentage.value ? multiply(divide(+amount, +origin_assets), 100) : amount
    })
    // 是否显示placeholder
    const isPlaceholder = computed(() => !(data.num || data.cursor))
    // placeholder文字
    const placeholder = isPercentage.value ? '至少0.001' : '10的整数倍'
    // 校验-错误信息
    const error = computed(() => {
      return useError(data.num, data.dataSource.amount, data.dataSource.origin_assets, isPercentage.value)
    })

    // 显示数字键盘
    const handleShowKeyboard = () => {
      data.keyboardVisible = true
      data.cursor = true
    }

    // 点击按键时
    const onInput = (key: number) => {
      data.num = data.num + key
    }
    // 点击删除键时
    const onDelete = () => {
      data.num = data.num.substring(0, data.num.length - 1)
    }

    // 点击关闭按钮或非键盘区域时
    const onBlur = () => {
      data.keyboardVisible = false
      data.cursor = false
    }

    // 点击数字键盘转账时
    const onSubmit = () => {
      let { num } = data
      if (num && !error.value) {
        data.keyboardVisible = false
        data.cursor = false
        // data.shareVisible = true
        data.modelVisible = true
      }
    }

    // 关闭分享遮罩层
    const handleCloseOverlay = () => {
      data.shareVisible = false
      data.num = ''
    }

    // 关闭弹层
    const handleCloseModel = () => {
      data.modelVisible = !data.modelVisible
      data.num = ''
    }

    // 确认弹层
    const handleSubmitModel = async () => {
      await submitDigitalTransfer().then((res) => {
        getMusicInfoData()
        handleTransferBtn(res as string)
        data.modelVisible = false
        data.shareVisible = true
      })
    }

    // 加载资产详情
    const getMusicInfoData = async () => {
      const res = await loadMusicInfoData(route.query.id as string)
      data.dataSource = res
    }

    // 提交转账信息
    const submitDigitalTransfer = () => {
      let params = {
        id: data.dataSource.utb_id,
        amount: 0,
      }
      if (isPercentage.value) {
        params.amount = multiply(divide(+data.num, 100), +data.dataSource.origin_assets)
      } else {
        params.amount = +data.num
      }
      // eslint-disable-next-line
      return new Promise(async (resolv, reject): Promise<void> => {
        const res = await sendDigitalTransfer(params)
        if (res) {
          resolv(res)
        } else {
          reject()
        }
      })
    }

    // 配置分享信息
    const handleTransferBtn = (id: string) => {
      const { title } = data.dataSource
      // eslint-disable-next-line
      const imgUrl = `https://oss.pengyin.vip/fd56dd274a2bfbaa90ca0c70660879e108da2e410db58bc50ee7ef014c877bd8.jpg?x-oss-process=image/auto-orient,1/resize,m_lfit,w_200/quality,q_90`
      const link = `${window.location.origin}/#/receive?order=${id}&timestamp=${new Date().getTime()}`
      let msg = {
        title: `《${title}》${data.num}${unitText}版权`,
        desc: '点击确认收版权',
        link,
        imgUrl,
      }
      let msg2 = {
        title: `《${title}》${data.num}${unitText}版权`,
        link,
        imgUrl,
      }
      wxShareInit(msg, msg2)
    }

    // 获取资产详情
    getMusicInfoData()
    return {
      ...toRefs(data),
      holder,
      unitText,
      isPlaceholder,
      placeholder,
      error,
      handleShowKeyboard,
      onInput,
      onDelete,
      onBlur,
      onSubmit,
      handleCloseOverlay,
      handleCloseModel,
      handleSubmitModel,
    }
  },
  components: {
    Back,
    ShareOverlay,
    Model,
  },
})
