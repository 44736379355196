import { multiply, divide } from '@/lib/math'

// 取余 - 看是否满足交易数量
const surplus = (num: number) => {
  return num && !(multiply(num, 1000) % 1)
}

/**
 * num 输入数量
 * hold 持有数量
 * sum 总发行量
 */
const useError = (num: string, hold: number, sum: number, isPercentage: boolean): string => {
  if (!num) {
    // 没有输入值不显示报错
    return ''
  } else {
    if (isPercentage) {
      // 百分比
      if (surplus(+num)) {
        const holder = multiply(divide(hold, sum), 100)
        if (+num > holder) {
          return '可用量不足'
        } else {
          return ''
        }
      } else {
        return '转账数量不符合规范，请检查后重试'
      }
    } else {
      // 张
      if (+num && !(+num % 10)) {
        if (+num > hold) {
          return '可用量不足'
        } else {
          return ''
        }
      } else {
        return '转账数量不符合规范，请检查后重试'
      }
    }
  }
}

export default useError
